import { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { debounce } from './Debounce'
import Colors from '../Colors'
import Loading from '../components/Loading'
import FieldErrorMessageComponent from './error/FieldErrorMessageComponent'
import styled from 'styled-components'

/**
 * Documentação: "https://react-select.com/props"
 * @param {Function} fetchSuggestions - Função que busca sugestões para o dropdown: (term: string): Promise<Array<{ value: any, label: string }>>
 * @param {string} placeholder - Placeholder que será exibido no input de filtro
 * @param {Array} value - Array de valores selecionados no dropdown
 * @param {Function} onChange - Função chamada sempre que é selecionado um item: (item: any) => {}
 * @param {boolean} isInvalid - Atributo que define se o campo é inválido
 * @param {boolean} isMulti - Atributo que define se é possível selecionar várias opções (para funcionar corretamente deve passar a função getSelectedOption)
 * @param {Function} getSelectedOption - Função chamada para cada item do dropdown e retorna o valor dele: (item: any): string => {}
 * @param {boolean} disabled - Desabilita o seletor
 * @param {Function} renderCustomComponent - Objeto de funções que retornam um JSX, as chaves devem seguir o nome dos componentes disponíveis: { control: (props) => JSX }
 * @param {Function} getCustomStyle - Objeto de funções que retornam estilos, as chaves devem seguir o nome dos componentes disponíveis: { control: (styles) => { ...styles } }
 * @param {boolean} useClearIndicator - Define se será utilizado um ícone para limpar toda a seleção
 * @param {boolean} useOrangeMode - Define se será utilizado o schema de cores laranja
 * @param {boolean} useCustomInput - Define se será utilizado input customizado, para utilizá-lo corretamente é necessário informar o "customInputText"
 * @param {boolean} customInputText - Objeto utilizado para informar plural e singular do "customInput": { plural: string, singular: string }
 * @param {number} zIndex - Define o z-index do container de seleções
 * @param {boolean} hideSelectedOptions - Define se as opções selecionadas serão escondidas por padrão: Default = false
 * @param {string} menuDataTestId - data-testid do menu
 */

export default function AutoCompleteAsync({
    fetchSuggestions,
    placeholder,
    value,
    onChange,
    isInvalid,
    isMulti,
    getSelectedOption,
    disabled,
    renderCustomComponent = {},
    getCustomStyle = {},
    useClearIndicator = true,
    useOrangeMode = false,
    useCustomInput = false,
    customInputText = { singular: '', plural: '' },
    zIndex = 'unset',
    hideSelectedOptions = false,
    menuDataTestId = 'auto-select-menu-list',
}) {
    const renderCustomInput = (props, children) => {
        const valueLength = value.length
        const getInputText = () => {
            return valueLength > 1
                ? `${valueLength} ${customInputText.plural}`
                : `1 ${customInputText.singular}`
        }
        if (valueLength) {
            const customPlaceHolder = getInputText()
            return (
                <CustomAutoCompleteInputContainer>
                    <span>{`${customPlaceHolder}`}</span>
                    <components.Input {...props} />
                </CustomAutoCompleteInputContainer>
            )
        }

        return <components.Input {...props}>{children}</components.Input>
    }

    function loadOptions(term, callback) {
        fetchSuggestions(term).then(options => {
            callback(options)
        })
    }

    function getColorModeStyles(styles, props, component) {
        if (!useOrangeMode) return styles

        switch (component) {
            case 'option':
                const defaultBackgroundColor = styles.backgroundColor
                const customSelectedBackgroundColor = Colors.opacityOrange
                const backgroundColor =
                    getSelectedOption && getSelectedOption(props.value)
                        ? customSelectedBackgroundColor
                        : defaultBackgroundColor

                return {
                    ...styles,
                    backgroundColor: backgroundColor,
                    color: Colors.brownishGrey,
                    [':active']: {
                        backgroundColor: backgroundColor,
                    },
                }
            case 'control':
                return {
                    ...styles,
                    borderColor: Colors.orange,
                    ['&:hover']: {
                        borderColor: Colors.orange,
                    },
                }
            default:
                return styles
        }
    }

    return (
        <div>
            <CustomAsyncSelect
                defaultOptions
                hideSelectedOptions={hideSelectedOptions}
                z-index={zIndex}
                isMulti={isMulti}
                isDisabled={disabled}
                value={value}
                placeholder={placeholder}
                onChange={(value, action) => onChange(value, action)}
                loadOptions={(term, callback) => {
                    console.log(term, term.length)
                    if (term.length >= 1) {
                        console.log('pica')
                        debounce(() => loadOptions(term, callback))
                        return
                    }
                    loadOptions(term, callback)
                }}
                theme={theme => ({
                    ...theme,
                    borderRadius: 4,
                    colors: {
                        ...theme.colors,
                        primary: Colors.opacityGrey,
                        primary25: Colors.opacityGrey,
                        neutral20: isInvalid
                            ? Colors.heavyRed
                            : Colors.lightBlueGreyTwo,
                        neutral80: Colors.brownishGrey,
                    },
                })}
                styles={{
                    ...customStyles,
                    container: styles => {
                        const baseStyles = getCustomStyle?.container
                            ? getCustomStyle.container(styles)
                            : styles

                        return {
                            ...baseStyles,
                            height: '40px',
                            fontFamily: 'Open Sans, Helvetica Neue',
                            fontSize: '14px',
                        }
                    },
                    placeholder: styles => {
                        const baseStyles = getCustomStyle?.placeholder
                            ? getCustomStyle.placeholder(styles)
                            : styles

                        return baseStyles
                    },
                    indicatorsContainer: styles => {
                        const baseStyles = getCustomStyle?.indicatorsContainer
                            ? getCustomStyle.indicatorsContainer(styles)
                            : styles

                        return baseStyles
                    },
                    dropdownIndicator: styles => {
                        const baseStyles = getCustomStyle?.dropdownIndicator
                            ? getCustomStyle.dropdownIndicator(styles)
                            : styles

                        return baseStyles
                    },

                    control: (styles, props) => {
                        const baseStyles = getCustomStyle?.control
                            ? getCustomStyle.control(styles)
                            : getColorModeStyles(styles, props, 'control')

                        return {
                            ...baseStyles,
                            borderColor: isInvalid
                                ? Colors.heavyRed
                                : baseStyles?.borderColor,
                            ['&:hover']: {
                                borderColor: isInvalid
                                    ? Colors.heavyRed
                                    : baseStyles?.borderColor,
                            },
                        }
                    },
                    input: styles => {
                        const baseStyles = getCustomStyle?.input
                            ? getCustomStyle.input(styles)
                            : styles

                        return baseStyles
                    },
                    menuList: styles => {
                        const baseStyles = getCustomStyle?.menuList
                            ? getCustomStyle.menuList(styles)
                            : styles

                        return baseStyles
                    },
                    option: (styles, props) => {
                        const baseStyles = getCustomStyle?.option
                            ? getCustomStyle.option(styles, props)
                            : getColorModeStyles(styles, props, 'option')

                        return {
                            ...baseStyles,
                            [':active']: {
                                backgroundColor: Colors.opacityGrey,
                            },
                        }
                    },
                }}
                components={{
                    MenuList: ({ children, ...props }) => {
                        return (
                            <div data-testid={menuDataTestId}>
                                <components.MenuList {...props}>
                                    {children}
                                </components.MenuList>
                            </div>
                        )
                    },
                    Input: ({ children, ...props }) => {
                        if (useCustomInput)
                            return renderCustomInput(props, children)

                        if (renderCustomComponent.input) {
                            return (
                                <CustomInputContainer>
                                    {renderCustomComponent.input(props)}
                                    <components.Input {...props} />
                                </CustomInputContainer>
                            )
                        }
                        return (
                            <components.Input {...props}>
                                {children}
                            </components.Input>
                        )
                    },
                    MultiValue: () => {
                        return <></>
                    },
                    ClearIndicator: ({ children, ...props }) => {
                        return useClearIndicator ? (
                            <components.ClearIndicator {...props}>
                                {children}
                            </components.ClearIndicator>
                        ) : (
                            <></>
                        )
                    },
                    LoadingIndicator: () => {
                        return <Loading />
                    },
                    Option: ({ children, ...props }) => {
                        const isSelected = getSelectedOption
                            ? getSelectedOption(props.value)
                            : props.isSelected

                        if (renderCustomComponent.option) {
                            return (
                                <components.Option
                                    {...props}
                                    isSelected={isSelected}
                                >
                                    {renderCustomComponent.option(props)}
                                </components.Option>
                            )
                        }

                        return (
                            <components.Option
                                {...props}
                                isSelected={isSelected}
                            >
                                {children}
                            </components.Option>
                        )
                    },
                }}
            />
            <FieldErrorMessageComponent isInvalid={isInvalid} />
        </div>
    )
}

const CustomAutoCompleteInputContainer = styled.div`
    font-family: Open Sans, Helvetica Neue;
    font-size: 14px;
    color: ${Colors.brownishGrey};
`

const CustomInputContainer = styled.div`
    display: flex;
    align-items: center;
`

const customStyles = {
    loadingIndicator: styles => ({
        ...styles,
        color: Colors.orange,
    }),
    dropdownIndicator: styles => ({
        ...styles,
        color: Colors.brownishGrey,
    }),
    menu: (styles, state) => ({
        ...styles,
        'z-index': 5,
        display: state.isLoading || !state.options.length ? 'none' : undefined,
    }),
    indicatorSeparator: styles => ({
        ...styles,
        backgroundColor: 'transparent',
    }),
}

const CustomAsyncSelect = styled(AsyncSelect)`
    margin: 0;
    padding: 0;
`
