import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useMillContext } from '../context/MillContext'

import { keyStorage } from '../commons/LocalStorage'
import ScreenHeader from '../commons/components/screenHeader/ScreenHeader'
import Loading from '../commons/components/Loading'

const AuthorizationRoute = ({ isStcRoute = true, children }) => {
    const location = useLocation()
    const { millUser } = useMillContext()

    const [isLoading, setIsLoading] = useState(true)

    const validationKey = isStcRoute
        ? keyStorage.stcUser
        : keyStorage.sellerUser
    const redirectPath = isStcRoute ? '/' : 'home/gestao-pedidos'

    useEffect(() => {
        if (millUser) setIsLoading(false)
    }, [millUser])

    if (isLoading) {
        return (
            <Container>
                <ScreenHeader />
                <LoadingContainer>
                    <Loading />
                </LoadingContainer>
            </Container>
        )
    }

    const prodapMills = [
        '2a28e656-a7bd-4304-ab9b-b71c98619b32', // Prodap - MG
        '56ffbfe1-64e2-4c14-9f04-cfdab4ecb027', // Prodap - MT
        '6c425db5-89f5-48d5-b979-1f1245df4281', // Desenvolvimento
        'edf7274e-22c6-497b-8896-9af5d4023c06', // Prodap Demonstração
        '6a96bb06-892f-4445-b3bf-1482b8ba7452', // (QA) Fabs Homologação
        'd001e92d-00d1-46e7-879d-142da6a57778', // (DEV) Fabs Desenvolvimento
    ]

    if (!isStcRoute && !prodapMills.includes(millUser.user.mill._id)) {
        return (
            <Redirect
                to={{
                    pathname: 'home/gestao-pedidos',
                    state: { from: location },
                }}
            />
        )
    }

    const isUserAuthorized = userType => userType.includes(validationKey)

    if (millUser && isUserAuthorized(millUser[keyStorage.aclUserGroups])) {
        return children
    } else {
        return (
            <Redirect
                to={{
                    pathname: redirectPath,
                    state: { from: location },
                }}
            />
        )
    }
}

const Container = styled.div`
    width: 100%;
`

const LoadingContainer = styled.div`
    position: fixed;
    left: 50%;
    top: 35%;
    margin: auto;
`

export default AuthorizationRoute
